
import TicketsList from '@/components/TicketsList.vue'
import { Component, Vue } from 'vue-property-decorator'
import Main from '@/layouts/Main.vue'

@Component({
  components: {
    TicketsList,
    Main,
  },
  metaInfo: {
    title: 'Tickets',
  },
})
export default class Tickets extends Vue {}
